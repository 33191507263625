import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import Layout from "../../templates/layout";
import { InlineWidget } from "react-calendly";
import { submitQueryForm } from "../../utils/contact.service";
import GetSVG from "../../svg/index";
const Contact = () => {
  const [isComponentMounted, setIsComponentMounted] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    query: "",
  });

  const [errors, setErrors] = useState({});
  useEffect(() => {
    setIsComponentMounted(true);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFocus = () => {
    if (Object.keys(errors).length > 0) setErrors({});
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name) {
      newErrors.name = "Name is required";
    }

    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Invalid email address";
    }

    if (!formData.query) {
      newErrors.query = "Query is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      submitQueryForm(formData)
        .then(() => {
          // Clear the form
          setFormData({
            name: "",
            email: "",
            query: "",
            msg: "Thanks for contacting VdoCipher. We shall reach you on your email.",
          });
        })
        .catch((error) => {
          console.error("Error sending data:", error);
        });
    }
  };

  return (
    <Layout>
      <Helmet
        title={
          " Contact VdoCipher: Get Support and Information on Video Security Solutions"
        }
        meta={[
          {
            name: "description",
            content:
              "Reach out to VdoCipher for queries on video DRM, secure streaming, and e-learning solutions. Contact us via email, WhatsApp, or phone for prompt support and information",
          },
        ]}
      />

      <div className="content contact-us">
        <div className="container">
          <div className="row">
            <div className="col l5 m5">
              <div className="row" style={{ marginTop: "68px" }}>
                <div className="col l6 m6">
                  <a class="mailto" href="mailto:support@vdocipher.com">
                    <div className="card-box cardlink">
                      <div className="icon-box">
                        <GetSVG svgName="email-colored" />
                      </div>
                      <div className="subContent">
                        <p>Email us at :</p>
                        <h3>support@vdocipher.com</h3>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col l6 m6">
                  <a href="https://wa.me/917042238654">
                    <div className="card-box cardlink">
                      <div className="icon-box">
                        <GetSVG svgName="whatsapp-logo-colored" />
                      </div>
                      <div className="subContent">
                        <p>Whatsapp Number :</p>
                        <h3>+91 7042238654</h3>
                      </div>
                    </div>
                  </a>
                </div>
                {/* <div className="col l6 m6">
                  <a href="tel:+1 3023141146">
                    <div className="card-box cardlink">
                      <div className="icon-box">
                        <GetSVG svgName="phonenumber" />
                      </div>
                      <div className="subContent">
                        <p>USA Number :</p>
                        <h3>+1 3023141146</h3>
                      </div>
                    </div>
                  </a>
                </div> */}
                <div className="col l6 m6">
                  <a href="tel:+917619171878">
                    <div className="card-box cardlink">
                      <div className="icon-box">
                        <GetSVG svgName="phonenumber" />
                      </div>
                      <div className="subContent">
                        <p>Call Number :</p>
                        <h3>+91 7619171878</h3>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col l12 m12">
                  {formData.msg ? (
                    <div class="cardSub">
                      <img
                        width="80"
                        src="https://www.vdocipher.com/blog/wp-content/uploads/2023/11/check.png"
                      />
                      <h2
                        style={{
                          fontSize: "22px",
                          color: "#595959",
                          textAlign: "center",
                          fontWeight: "400",
                          marginBottom: "10px",
                          paddingTop: "30px",
                        }}
                      >
                        Thanks for contacting VdoCipher.
                      </h2>
                      <h2
                        style={{
                          fontSize: "18px",
                          color: "#595959",
                          textAlign: "center",
                          fontWeight: "200",
                        }}
                      >
                        We will reply on email within 1-2 working days!
                      </h2>
                    </div>
                  ) : (
                    <>
                      <div className="main-heading">Submit Your Query Here</div>
                      <div className="card-box" style={{ padding: "20px" }}>
                        <div className="row">
                          <form onSubmit={handleSubmit}>
                            <div className="col l6 m6 inputfocus">
                              <label>Name</label>
                              <input
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                onFocus={handleFocus}
                                className="inputstyle"
                              />
                              {errors.name && (
                                <p
                                  style={{
                                    color: "#ff0000",
                                    paddingTop: "6px",
                                  }}
                                >
                                  {errors.name}
                                </p>
                              )}
                            </div>
                            <div className="col l6 m6 inputfocus">
                              <label>Email</label>
                              <input
                                type="text"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                onFocus={handleFocus}
                                className="inputstyle"
                              />
                              {errors.email && (
                                <p
                                  style={{
                                    color: "#ff0000",
                                    paddingTop: "6px",
                                  }}
                                >
                                  {errors.email}
                                </p>
                              )}
                            </div>

                            <div className="col l12 m12 tp-spc">
                              <label>Query</label>
                              <textarea
                                name="query"
                                value={formData.query}
                                onChange={handleChange}
                                onFocus={handleFocus}
                                className="textarea"
                              />
                              {errors.query && (
                                <p style={{ color: "#ff0000" }}>
                                  {errors.query}
                                </p>
                              )}
                            </div>
                            <div
                              className="col l12 m12"
                              style={{ color: "green" }}
                            >
                              {formData.msg}
                            </div>
                            <button
                              className="submitButton"
                              type="submit"
                              style={{ marginLeft: "12px" }}
                            >
                              Submit
                            </button>
                          </form>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col l7 m7">
              {isComponentMounted && (
                <InlineWidget
                  open={true}
                  text="SCHEDULE"
                  className="schedule-demo-button"
                  rootElement={document.getElementById("CalendlyHolder")}
                  url="https://calendly.com/vdocipher/demo-link"
                  pageSettings={{
                    primaryColor: "5f50e4",
                    hideLandingPageDetails: true,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Contact;
