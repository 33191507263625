import { simpleRequest } from "./api";

export const submitContactForm = ({ data }) => {
  return simpleRequest({
    url: "/site/contact",
    method: "POST",
    data,
  });
};

export const submitQueryForm = (data) => {
  return simpleRequest({
    url: "/enquiry-form",
    method: "POST",
    data,
  });
};
